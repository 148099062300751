import { render, staticRenderFns } from "./Swap.vue?vue&type=template&id=289c6901&scoped=true"
import script from "./Swap.vue?vue&type=script&lang=js"
export * from "./Swap.vue?vue&type=script&lang=js"
import style0 from "./Swap.vue?vue&type=style&index=0&id=289c6901&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "289c6901",
  null
  
)

export default component.exports