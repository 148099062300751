//代币列表
export const BNBlist = [
  {
      "chainId": 56,
      "address": "0x55d398326f99059fF775485246999027B3197955",
      "name": "USDT",
      "symbol": "USDT",
      "decimals": 18,
      "logoURI": require('@/assets/img/logo/USDT.png')
    },
    {
      "chainId": 56,
      "address": "0xDb34E4e713468B8b9b710187e9BBD8B15143BCdF",
      "name": "R",
      "symbol": "R",
      "decimals": 18,
      "logoURI": require('@/assets/tokenlogo/R.png')
    },
    {
      "chainId": 56,
      "address": "0xaa823607c1e7b9f270328160e11f5b024fed43fe",
      "name": "RUSD",
      "symbol": "RUSD",
      "decimals": 18,
      "logoURI": require('@/assets/tokenlogo/RUSD.png')
    },
    {
      "chainId": 56,
      "address": "0x684033bd95d3bb92affde013c1e3c5266567c2ce",
      "name": "RT",
      "symbol": "RT",
      "decimals": 18,
      "logoURI": require('@/assets/tokenlogo/RT.png')
    },
]



